import '../scss/index.scss';
import '@smithsonian/js';
import '@smithsonian/js/formChange.js';
import $ from 'jquery';

import * as Sentry from '@sentry/browser';

if (window.SENTRY_DSN) {
    Sentry.init({
        // smithsonian-frontend project
        dsn: window.SENTRY_DSN,
        environment: window.ENV,

        release: `smithsonianmag@${BUILD_ID}+${window.RELEASE}`,

        // Rate 1.0 to capture 100%
        sampleRate: 0.00002,

        // Disable session tracking for now
        autoSessionTracking: false,

        integrations: [
            Sentry.thirdPartyErrorFilterIntegration({
                filterKeys: ['smithsonian-sentry'],
                behaviour: 'drop-error-if-contains-third-party-frames',
            }),
        ],
    });
}

// Close alert banner
$(document).ready(function () {
    $('.alert-banner .fa-circle-xmark').on('click', function () {
        console.log('x clicked');
        $(this).parent().hide();
    });
});

// Mobile menu
$(document).ready(function () {
    var menuCount = 0;
    $('#mobileIcon, #closeSideNav').on('click', function () {
        $('#mobileIcon').toggleClass('open');
        $('#sideNav').toggleClass('open');
        if (menuCount % 2 === 0) {
            $('#mobileIcon').attr('aria-expanded', 'true');
        } else {
            $('#mobileIcon').attr('aria-expanded', 'false');
        }
        menuCount++;
    });
});

// Homepage header scroll logo
$(document).ready(function () {
    if ($('.largemasthead .site-logo').length) {
        if ($(window).width() > 600) {
            var distance =
                $('.headerBar .headerBaritem.logo').offset().top + 100;
            $('.headerBar .headerBaritem.logo').css('opacity', 0);
            $(window).on('scroll', function () {
                if ($(window).scrollTop() >= distance) {
                    $('header.main .headerBar').addClass('scrolled');
                    $('.headerBar .headerBaritem.logo').css('opacity', 1);
                } else {
                    $('header.main .headerBar').removeClass('scrolled');
                    $('.headerBar .headerBaritem.logo').css('opacity', 0);
                }
            });
        } else {
            var distance = $('.main-content.homepage').offset().top - 50;
            $('.headerBar .headerBaritem.logo').css('opacity', 1);
        }
    }
});

// Fix sidebar ad on scroll
$(document).ready(function () {
    if ($('aside .cbv-ad').length && $(window).width() > 800) {
        var distance = $('aside .cbv-ad').offset().top - 80;

        $(window).on('scroll', function () {
            if ($(window).scrollTop() >= distance) {
                $('aside .widget-ad-sticky').addClass('scrolled');
            } else {
                $('aside .widget-ad-sticky').removeClass('scrolled');
            }
        });
    }
});

// Reorder homepage most popular section on mobile
$(document).ready(function () {
    if ($(window).width() <= 600 && $('.most-popular-homepage').length) {
        $('.most-popular-large .article-list-item .article-list-counter').each(
            function () {
                $(this).insertAfter($(this).next('.article-list-image'));
            }
        );
    }
});

// Adjust special report featured image caption for mobile
$(document).ready(function () {
    if ($(window).width() < 1001 && $('.special-report-header').length) {
        $('figcaption.caption').insertAfter(
            '.special-report-header .special-report-column:first-of-type'
        );
    }
});

// Temporary adjustment for Panama sponsored page logo
$(document).ready(function () {
    if (window.location.href.indexOf('/travel/panama-180980230/') > -1) {
        $('.special-report-header.two-columns').css('border-bottom', 'none');
        $(
            '.special-report-header.two-columns .special-report-text-row img'
        ).css('max-height', '120px');
    }
});

// Slideshow
$(document).ready(function () {
    $('.slideshow .slideshow-next').on('click', function (e) {
        e.preventDefault();

        var currentSlide = $(this)
            .closest('.slideshow')
            .find('.slide:visible')
            .index();
        var totalSlides = $(this).closest('.slideshow').find('.slide').length;

        $(this).closest('.slideshow').find('.slide').eq(currentSlide).hide();
        $(this)
            .closest('.slideshow')
            .find('.slide')
            .eq(currentSlide + 1)
            .show();

        if ($(this).closest('.slideshow').find('.slide:visible').length == 0) {
            $(this).closest('.slideshow').find('.slide').eq(0).show(0);
        }

        // Update slideshow markers
        var nextMarker = (currentSlide + 1) % totalSlides;
        $(this)
            .closest('.slideshow')
            .find('.slide-markers')
            .children('.greybar')
            .removeClass('active');
        $(this)
            .closest('.slideshow')
            .find('.slide-markers')
            .children('.greybar:nth-of-type(' + (nextMarker + 1) + ')')
            .addClass('active');
    });

    $('.slideshow .slideshow-prev').on('click', function (e) {
        e.preventDefault();

        var currentSlide = $(this)
            .closest('.slideshow')
            .find('.slide:visible')
            .index();
        var totalSlides = $(this).closest('.slideshow').find('.slide').length;

        $(this).closest('.slideshow').find('.slide').eq(currentSlide).hide();
        $(this)
            .closest('.slideshow')
            .find('.slide')
            .eq(currentSlide - 1)
            .show();

        if ($(this).closest('.slideshow').find('.slide:visible').length == 0) {
            $(this)
                .closest('.slideshow')
                .find('.slide')
                .eq(totalSlides - 1)
                .show(0);
        }

        // Update slideshow markers
        var prevMarker = (currentSlide - 1 + totalSlides) % totalSlides;
        $(this)
            .closest('.slideshow')
            .find('.slide-markers')
            .children('.greybar')
            .removeClass('active');
        $(this)
            .closest('.slideshow')
            .find('.slide-markers')
            .children('.greybar:nth-of-type(' + (prevMarker + 1) + ')')
            .addClass('active');
    });

    // Current slide number
    $('.slideshow').each(function () {
        $(this)
            .find('.slide')
            .each(function () {
                $(this)
                    .find('.current-slide')
                    .text($(this).index() + 1);
            });
        $(this)
            .find('.total-slides')
            .text($(this).find('.slideshow-wrap').children().length);
    });

    // Hide slide markers if there are more than 5 slides
    if ($('.greybar').length > 5) {
        $('.slide-markers').hide();
    }
});

// Article detail page load Disqus on button click
$(document).ready(function () {
    $('.article-comments #trigger').on('click', function () {
        var identifier = $(this).attr('disqus_id');
        $(
            "<script type='text/javascript'>var disqus_shortname = 'smithsonianmagazine',disqus_identifier ='" +
                identifier +
                "';(function() {var dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true;dsq.src = '//' + disqus_shortname + '.disqus.com/embed.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);})();</script>"
        ).appendTo('#disqus_thread');
        $(this).hide();
    });
});

// Issue archive update page on year selection
$(document).on('change', '#issueYear', function () {
    var currentYear = $(this).val();
    window.location = currentYear;
});

// Long form article hero responsive adjustment
$(document).ready(function () {
    if ($(window).width() < 1001) {
        $(
            '.longform-header-container .longform-full-width .full-width-container .full-width-gradient .full-width-content'
        ).insertAfter(
            '.longform-header-container .longform-full-width .full-width-container .full-width-gradient .desktop-heading img'
        );
        if ($('.longform-header-container').length) {
            $('.longform-header-container figcaption').insertAfter(
                '.full-width-container .desktop-heading img'
            );
        } else {
            $('.longform-column.with-text').insertAfter(
                '.longform-column.hero-image'
            );
            $('.longform-header-container figcaption').insertAfter(
                '.longform-column.hero-image'
            );
        }
    }
});

// Open external links in new tab (exclude You Might Also Like section)
$(document).ready(function () {
    if ($('.articleLeft').length || $('article.longform').length) {
        $('.articleLeft a[href^=http]')
            .not('#id_related_pages a')
            .each(function () {
                $(this).attr('target', '_blank');
            });

        $('.article-fullwidth-column a[href^=http]')
            .not('#id_related_pages a')
            .each(function () {
                $(this).attr('target', '_blank');
            });
    }
});

$(document).ready(function () {
    $('.sponsored_promo_item').click(function (e) {
        var target = e.currentTarget;
        if (
            target.hasAttribute('data-toggle') &&
            target.getAttribute('data-toggle') == 'modal'
        ) {
            if (target.hasAttribute('data-target')) {
                var m_ID = target.getAttribute('data-target');
                document.getElementById(m_ID).classList.add('open');
                e.preventDefault();
            }
        }

        // Close modal window with 'data-dismiss' attribute or when the backdrop is clicked
        // if(e.keyCode == 27) {
        //     if ($('body').hasClass('open')) {
        //         $('body').removeClass('open');
        //     }
        // }
        // if ((target.hasAttribute('data-dismiss') && target.getAttribute('data-dismiss') == 'modal') || target.classList.contains('modal')) {
        //     var modalopen = document.querySelector('[class="modal open"]');
        //     modalopen.classList.remove('open');
        //     e.preventDefault();
        // }
    });
    $('.close').click(function (e) {
        var closetarget = e.currentTarget;
        if (
            (closetarget.hasAttribute('data-dismiss') &&
                closetarget.getAttribute('data-dismiss') == 'modal') ||
            closetarget.classList.contains('modal')
        ) {
            var modalopen = document.querySelector('[class="modal open"]');
            modalopen.classList.remove('open');
            e.preventDefault();
        }
    });
    $(document).keydown(function (e) {
        if (e.keyCode == 27) {
            var modalopen = document.querySelector('[class="modal open"]');
            modalopen.classList.remove('open');
        }
    });
});

// Hotkey to hide CMS toolbar (9+0)
const down = {};

document.addEventListener('keydown', function (e) {
    down[e.keyCode] = true;
});

document.addEventListener('keyup', function (e) {
    if (down[57] && down[48]) {
        document.querySelector('body').classList.toggle('hide-toolbar');
    }
    down[e.keyCode] = false;
});

if (
    document.location.href.indexOf('www') > -1 ||
    document.location.href.indexOf('localhost') > -1
) {
    // Sticky article page leaderboard ad for 2 seconds
    let hasExecuted = false;

    function stickyLeaderboardAdOnScroll(className) {
        // Get the leaderboard by class name
        const leaderboard = document.querySelector(className);

        // Get the distance from the top of the document to the top of the leaderboard
        const leaderboardTop = leaderboard.getBoundingClientRect().top;

        // Get the height of the ad container
        let adContainerHeight = document.querySelector(className).offsetHeight;
        const bodyPadding = parseInt(
            window.getComputedStyle(document.body).paddingTop
        );
        let newBodyPadding = bodyPadding + adContainerHeight;
        // console.log('Height: ' + adContainerHeight + ', Padding: ' + bodyPadding + ', New Padding: ' + newBodyPadding);

        // Update spacing when ad is loaded into container
        const targetElement = document.querySelector(
            '.advertisement.fullwidth.adtop div'
        );

        const observer = new MutationObserver(function (mutations) {
            setTimeout(function () {
                adContainerHeight =
                    document.querySelector(className).offsetHeight;
                newBodyPadding = bodyPadding + adContainerHeight;
                // console.log('New Height: ' + adContainerHeight + ', Padding: ' + bodyPadding + ', New Padding: ' + newBodyPadding);
            }, 1000);
        });

        const config = { childList: true };

        observer.observe(targetElement, config);

        // Add an event listener for the 'scroll' event
        window.addEventListener('scroll', function () {
            // Get the distance the page has been scrolled
            const scrollY = window.scrollY;
            console.log('ad scrolled');

            // If the top of the leaderboard is scrolled to or past, add the class
            if (scrollY >= leaderboardTop) {
                // Prevent function from running more than once
                if (hasExecuted) {
                    return;
                }

                leaderboard.classList.add('sticky');
                this.document.querySelector('body').style.paddingTop =
                    newBodyPadding + 'px';

                // Remove class after 2 seconds and reset body padding
                setTimeout(function () {
                    leaderboard.classList.remove('sticky');
                    this.document.querySelector('body').style.paddingTop =
                        bodyPadding + 'px';
                    hasExecuted = true;
                }, 5000);
            } else {
                // If not, remove the class and reset body padding
                leaderboard.classList.remove('sticky');
                this.document.querySelector('body').style.paddingTop =
                    bodyPadding + 'px';
            }
        });
    }

    // Only run the function if the ad element exists on the page and the user's browser window is at least 1000 pixels wide
    if (document.querySelector('.advertisement.fullwidth.adtop')) {
        // stickyLeaderboardAdOnScroll('.advertisement.fullwidth.adtop');
    }
}

$(document).ready(function () {
    const toggleOneYear = document.querySelector('#toggle_one_year');
    const toggleNeverExpire = document.querySelector('#toggle_never_expire');

    $('#licensing-form-add-item-btn').on('click', function (ev) {
        ev.preventDefault();
        var count = $('#items').children().length;
        var tmplMarkup = $('#item-template').html();
        var compiledTmpl = tmplMarkup.replace(/__prefix__/g, count);
        $('#items').append(compiledTmpl);

        // update form count
        $('#id_items-TOTAL_FORMS').attr('value', count + 1);
    });

    if (toggleOneYear) {
        toggleOneYear.onclick = function () {
            document
                .querySelectorAll('[data-license="one_year"]')
                .forEach(function (element) {
                    element.style.display = 'block';
                });
            document
                .querySelectorAll('[data-license="never_expire"]')
                .forEach(function (element) {
                    element.style.display = 'none';
                });
        };
    }

    if (toggleNeverExpire) {
        toggleNeverExpire.onclick = function () {
            document
                .querySelectorAll('[data-license="one_year"]')
                .forEach(function (element) {
                    element.style.display = 'none';
                });
            document
                .querySelectorAll('[data-license="never_expire"]')
                .forEach(function (element) {
                    element.style.display = 'block';
                });
        };
    }
});

// Hide or show secondary side navigation menu close button depending on whether the header bar is at the top of the screen or pushed down by the leaderboard ad
document.addEventListener('DOMContentLoaded', function () {
    const headerBar = document.querySelector('.headerBar');
    const closeSideNav = document.querySelector('#closeSideNav');

    // Check position and update visibility
    function checkHeaderPosition() {
        const headerRect = headerBar.getBoundingClientRect();

        if (Math.abs(headerRect.top) < 2) {
            // HeaderBar is at the very top
            closeSideNav.style.display = 'none';
        } else {
            // HeaderBar is not at the top
            closeSideNav.style.display = 'block';
        }
    }

    // Throttle function to limit frequency of calls
    function throttle(func, limit) {
        let inThrottle;
        return function () {
            const args = arguments;
            const context = this;
            if (!inThrottle) {
                func.apply(context, args);
                inThrottle = true;
                setTimeout(() => (inThrottle = false), limit);
            }
        };
    }

    // Create throttled version of the function (run at most every 100ms)
    const throttledCheckPosition = throttle(checkHeaderPosition, 100);

    // Add scroll listener with throttling
    window.addEventListener('scroll', throttledCheckPosition);

    // Check immediately
    checkHeaderPosition();
});

// Hide subscription banner for 30 days after closed by user
function handleSubscribeBannerClose() {
    // Set the expiration date to 30 days from now
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);

    // Store the expiration date in localStorage
    localStorage.setItem('subscribeBannerClosed', expirationDate.toString());

    // Hide the subscribe banner
    document.getElementById('subscribeBanner').style.display = 'none';
}

function checkSubscribeBannerStatus() {
    const subscribeBannerClosedDate = localStorage.getItem(
        'subscribeBannerClosed'
    );

    if (subscribeBannerClosedDate) {
        const expirationDate = new Date(subscribeBannerClosedDate);
        const currentDate = new Date();

        // If the current date is before the expiration date, don't show the banner
        if (currentDate < expirationDate) {
            return false;
        }
    }

    // Show the banner if no record exists or if 30 days have passed
    return true;
}

// When page loads, check if subscription banner should be shown
window.addEventListener('DOMContentLoaded', () => {
    const shouldShowBanner = checkSubscribeBannerStatus();

    if (shouldShowBanner) {
        document.getElementById('subscribeBanner').style.display = 'block';
    } else {
        document.getElementById('subscribeBanner').style.display = 'none';
    }

    // Add event listener to close button
    const closeButton = document.getElementById('subscribeBannerCloseButton');
    if (closeButton) {
        closeButton.addEventListener('click', handleSubscribeBannerClose);
    }
});

// Reposition the button on the special plugin container for mobile
document.addEventListener('DOMContentLoaded', function () {
    const button = document.querySelector(
        '.home-special-report-container .button'
    );
    const targetLocation = document.querySelector(
        '.home-special-report-container .special-sponsored'
    );
    const originalParent = button.parentNode;
    const originalNextSibling = button.nextSibling;

    // Track if the button has been moved
    let buttonMoved = false;

    function repositionButton() {
        if (window.innerWidth < 1001 && !buttonMoved) {
            // Move the button to bottom of container on mobile
            if (button && targetLocation) {
                targetLocation.parentNode.insertBefore(
                    button,
                    targetLocation.nextSibling
                );
                buttonMoved = true;
            }
        } else if (window.innerWidth >= 1001 && buttonMoved) {
            // Restore the button to its original position
            if (button && originalParent) {
                originalParent.insertBefore(button, originalNextSibling);
                buttonMoved = false;
            }
        }
    }

    // Run on page load
    repositionButton();

    // Run when the window is resized
    window.addEventListener('resize', repositionButton);
});
